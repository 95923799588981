import React from 'react';

import styled from 'styled-components';
import tw from 'twin.macro';

const myImagePath = 'https://beyondarful.s3-ap-southeast-2.amazonaws.com/bg.jpg';

const BgContainer = styled('div')`
    height: 100vh;
    width: 100%;
    background-image: url(${myImagePath});
    position: relative;
`;

const BgOverlay = styled('div')`
    height: 100%;
    width: 100%;
    background-image: url(${myImagePath});
    background: #5091da;
    position: absolute;
    opacity: 0.3;
    z-index: 2;
`;

const MainHeader = styled('h1')`
    font-size: 7rem;
    text-shadow: 1px 1px 18px rgba(0, 0, 0, 0.3);
`;

const Content = styled('div')`
    position: relative;
    z-index: 3;
`;

export default function Home(): React.ReactElement {
    return (
        <BgContainer className="text-white bg-center bg-cover flex flex-1 justify-center items-center font-roboto">
            <Content className="text-center ">
                <p className="uppercase">eruptive ideas</p>
                <MainHeader className="uppercase font-bold">Beyond Artful</MainHeader>
                <p className="uppercase">endless possibilities</p>
            </Content>
            <BgOverlay />
        </BgContainer>
    );
}
